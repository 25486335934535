/**
 * Return a formatted price string for any given input value, based on the
 * locale and currency settings in the `.env` file.
 *
 * @param {any} price - A numeric value
 * @returns {string} - A formatted price string;
 *   falls back to `0` when the input string doesn't fit,
 *   returns the input string when it already contains the currency symbol
 */
export default function formatPrice(price) {
  // Assume that a given string is already formatted when the currency symbol
  // is present
  if (
    typeof price === 'string' &&
    price.indexOf(process.env.CURRENCYSYMBOL) > -1
  ) {
    return price
  }

  const obj = new Intl.NumberFormat(process.env.LOCALE, {
    style: 'currency',
    currency: process.env.CURRENCY,
  })

  if (isNaN(price) || typeof price === 'boolean') {
    return obj.format(0)
  }
  return obj.format(price)
}
