import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  dispatchHideOverlayEvent,
  dispatchShowOverlayEvent,
  fetchAutosuggestResult,
  submitSearchForm,
  useGlobalData,
  useTranslation,
} from '../../../../utils'
import { Icon } from '../../../index'

import { useIcons } from '../IconProvider'
import Results from './Results'

function SearchBar({
  className,
  afterSubmit,
  escapeAction,
  showCloseButton,
  showClearButton,
  showSubmitButton,
  showOverlay,
  portalNode,
  isSearchActive,
  borderStyle,
  buttonStyle,
  placeholder,
}) {
  const { params } = useGlobalData() || {}
  const { t, language } = useTranslation()
  const getIcon = useIcons()
  const [searchPhrase, setSearchPhrase] = useState(params?.searchPhrase || '')
  const [searchResults, setSearchResults] = useState(null)
  const inputRef = useRef(null)

  useEffect(() => {
    let timeout
    if (isSearchActive) {
      // Mobile 'bar' layout shows an overlay, desktop and mobile 'wall' layout don't
      if (showOverlay) {
        dispatchShowOverlayEvent()
        window.addEventListener('overlay:clicked', escapeAction)
      }
      // Focus the input when the search activates
      timeout = setTimeout(() => {
        inputRef?.current?.focus()
        clearTimeout(timeout)
      }, 200) // Delay for transitions
      inputRef?.current?.focus() // iOS requires a synced call
    } else if (showOverlay) {
      window.removeEventListener('overlay:clicked', escapeAction)
    }
    return () => {
      if (isSearchActive) {
        // Cancel the delayed focus when the search closes, otherwise on-screen keyboards stay active
        clearTimeout(timeout)
        if (showOverlay) {
          dispatchHideOverlayEvent()
          window.removeEventListener('overlay:clicked', escapeAction)
        }
      }
    }
  }, [isSearchActive, escapeAction, showOverlay])

  useEffect(() => {
    // Fill or clear the search input when the query parameter changes
    setSearchPhrase(params?.searchPhrase || '')
  }, [params?.searchPhrase])

  const onKeyUp = (evt) => {
    if (evt.key === 'Escape') {
      evt.target.blur()
      setSearchResults(null)
      if (typeof escapeAction === 'function') {
        escapeAction()
      }
    }
  }

  const onChange = async (evt) => {
    const searchPhrase = evt.target.value
    setSearchPhrase(searchPhrase)
    const results =
      (await fetchAutosuggestResult({ searchPhrase, language }).catch(
        // Ignore errors here because there will likely be a lot of them. An
        // empty result list is perfectly fine when Makaira is not reachable.
        () => {}
      )) || []
    setSearchResults(results)
  }

  const onSubmit = async (evt) => {
    evt.preventDefault()
    setSearchResults(null)
    if (searchPhrase) {
      await submitSearchForm({ searchPhrase, language })
      if (typeof afterSubmit === 'function') {
        afterSubmit()
      }
    }
  }

  const onClear = () => {
    setSearchPhrase('')
    setSearchResults(null)
    inputRef?.current?.focus()
  }

  const containerClasses = classNames('header__search', className)
  const inputGroupClasses = classNames('input-group', {
    'input-group--rounded-corners': borderStyle === 'round',
  })
  const btnCloseClasses = 'reset-btn input-group-prepend py-0'
  const btnSubmitClasses = classNames('input-group-append border-0', {
    'btn btn-primary pl-3': buttonStyle === 'solid',
    'reset-btn': buttonStyle === 'transparent',
  })
  const btnClearClasses = 'input-group-append reset-btn'

  const content = (
    <div id="search-bar" className={containerClasses}>
      <form className="header__search-form w-100" onSubmit={onSubmit}>
        <div className={inputGroupClasses}>
          {showCloseButton && escapeAction && (
            <button
              type="button"
              className={btnCloseClasses}
              onClick={escapeAction}
              aria-label={t('SEARCH_CLOSE')}
              title={t('SEARCH_CLOSE')}
            >
              <Icon symbol={getIcon('searchBack')} />
            </button>
          )}
          <input
            type="text"
            name="searchPhrase"
            ref={inputRef}
            value={searchPhrase}
            placeholder={placeholder}
            onChange={onChange}
            onKeyUp={onKeyUp}
            autoComplete="off"
            autoCorrect="off"
            enterKeyHint="search"
            required={true}
          />
          {showClearButton && !!searchPhrase && (
            <button
              type="button"
              onClick={onClear}
              className={btnClearClasses}
              aria-label={t('SEARCH_CLEAR')}
            >
              <Icon symbol={getIcon('searchClear')} className="font-size-sm" />
            </button>
          )}
          {showSubmitButton && (
            <button
              type="submit"
              className={btnSubmitClasses}
              aria-label={t('SEARCH_SUBMIT')}
            >
              <Icon symbol={getIcon('searchSubmit')} className="font-size-sm" />
            </button>
          )}
        </div>
      </form>
      <Results
        searchResults={searchPhrase ? searchResults : null}
        searchTerm={searchPhrase}
      />
    </div>
  )

  if (portalNode) {
    return createPortal(content, portalNode)
  }
  return content
}

SearchBar.propTypes = {
  className: PropTypes.string,
  afterSubmit: PropTypes.func,
  escapeAction: PropTypes.func,
  showCloseButton: PropTypes.bool,
  showClearButton: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
  showOverlay: PropTypes.bool,
  portalNode: PropTypes.any,
  isSearchActive: PropTypes.bool,
  borderStyle: PropTypes.string,
  buttonStyle: PropTypes.string,
  placeholder: PropTypes.string,
}

SearchBar.defaultProps = {
  className: null,
  afterSubmit: null,
  escapeAction: null,
  showCloseButton: true,
  showClearButton: true,
  showSubmitButton: true,
  showOverlay: false,
  portalNode: null,
  isSearchActive: false,
  borderStyle: 'angled',
  buttonStyle: 'solid',
  placeholder: '',
}

export default SearchBar
