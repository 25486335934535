import React, { Component } from 'react'
import classNames from 'classnames'

export default class QtySelect extends Component {
  constructor(props) {
    super(props)
  }

  increase = () => {
    if (this.props.qty < this.props.max) {
      this.props.changeQty(this.props.qty + 1)
    }
  }

  decrease = () => {
    if (this.props.qty > this.props.min) {
      this.props.changeQty(this.props.qty - 1)
    }
  }

  updateQty = (event) => {
    this.props.changeQty(parseInt(event.target.value))
  }

  render() {
    const wrapperClasses = classNames('qty-wrapper', this.props.className, {
      disabled: this.props.isDisabled,
    })
    return (
      <QtyBox
        wrapperClasses={wrapperClasses}
        qty={this.props.qty}
        updateQty={this.updateQty}
        type={this.props.type}
        increase={this.increase}
        decrease={this.decrease}
        productId={this.props.productId}
      />
    )
  }
}

function QtyBox(props) {
  const {
    wrapperClasses,
    qty = 1,
    updateQty,
    type,
    increase,
    decrease,
    productId,
  } = props
  let qtyBox = ''
  if (type == 'inputBox') {
    qtyBox = (
      <>
        <div className={wrapperClasses}>
          <span className="cf-minus" onClick={decrease} />
          <input
            className="qty text-center border"
            type="number"
            min="1"
            max="10"
            name="qty"
            placeholder="qty"
            value={qty}
            onChange={updateQty}
            readOnly
          />
          <span className="cf-plus" onClick={increase} />
        </div>
      </>
    )
  } else {
    qtyBox = (
      <select
        onChange={updateQty}
        className="form-control"
        name="qty"
        id={'qty-' + productId}
        value={qty}
      >
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>
    )
  }
  return <>{qtyBox}</>
}
